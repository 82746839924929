<template>
  <div class="setting_main_bg">
    <div class="build_cont">
      <div class="build_cont_box">
        <div class="btn_group" v-if="companyid === ''">
          <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
        </div>
        <div class="flexAC" :class="companyid === ''?'ut_top':''">
          <el-input v-model="buildname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <el-select v-model="high" filterable placeholder="是否高层"  class="utG setSe">
            <el-option
              v-for="item in highOption"
              :key="item.vaule"
              :label="item.name"
              :value="item.vaule">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="build_table">
          <el-table
            stripe
            :data="buildList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
<!--            <el-table-column-->
<!--              label="单位名称"-->
<!--              prop="companyname"-->
<!--              show-overflow-tooltip>-->
<!--            </el-table-column>-->
            <el-table-column
              label="建筑名称"
              prop="buildname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="建筑类型"
              prop="datavalue"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="是否高层"
              prop="ishigh">
              <template slot-scope="scope">
                <span v-if="scope.row.ishigh == false">否</span>
                <span v-if="scope.row.ishigh == true">是</span>
              </template>
            </el-table-column>
            <el-table-column
              label="层数"
              prop="buildlayer">
            </el-table-column>
            <el-table-column
              label="添加时间"
              prop="creatime">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                <el-button type="text" size="small" v-if="companyid === ''" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" v-if="companyid === ''" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 新增建筑 -->
    <el-dialog title="建筑新增" :visible.sync="addFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="addFromCancel" top="8vh">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <div class="flexCB">
<!--          <el-form-item label="单位名称" class="foItem"  prop="companyid" required>-->
<!--            <el-select v-model="addfrom.companyid" placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%">-->
<!--              <el-option v-for="item in companyList" :key="item.index"-->
<!--                         :label="item.companyname"-->
<!--                         :value="item.companyid">-->
<!--                {{ item.companyname }}</el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="建筑名称"  class="foItem" prop="buildname">
            <el-input v-model="addfrom.buildname"  placeholder="请输入建筑名称"></el-input>
          </el-form-item>
          <el-form-item label="建筑类型" class="foItem" prop="buildtype">
            <el-select v-model="addfrom.buildtype" placeholder="请下拉选择建筑类型" class="adSe" style="width: 100%">
              <el-option
                v-for="item in buildTypeList"
                :key="item.dictid"
                :label="item.datavalue"
                :value="item.dictid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="foItem flexAC">
          <el-form-item label="楼层" prop="lfool">
              <el-input v-model="addfrom.lfool"  placeholder="起始楼层" class="flNum"></el-input>
          </el-form-item>
          <div style="line-height: 40px;margin: 0px 4px;"><span>至</span></div>
          <el-form-item prop="rfool" style="margin-bottom: 0px;">
              <el-input v-model="addfrom.rfool"  placeholder="终止楼层" class="flNum"></el-input>
          </el-form-item>
          <el-button class="settingSearch" v-if="cadShow === false" @click="foolAdd">添加</el-button>
          <el-button class="settingSearch" v-if="cadShow === true" @click="foolRes">重置</el-button>
          <el-upload
            accept=".jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
            class="settingExport"
            :disabled="downShow"
            :class="downShow === true?'disabled':''"
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleSuccessAll">
            <div class="FoolBtn">上传图纸</div>
          </el-upload>
        </div>
        <div class="CAD_box" v-if="cadShow === true">
          <div class="upBox" v-for="(item, index) in imgPathList" :key="index">
            <label class="FoolNum">{{ item.label }}层</label>
            <el-upload
              class="cadUpload"
              accept=".jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-change="(file, fileList) => { getIndex(index) }">
              <div class="FoolBtn" v-if="item.name === ''">上传图片</div>
              <label class="upName" v-if="item.name !== ''">{{ item.name }}</label>
            </el-upload>
            <label class="el-icon-view upIcoT" v-if="item.name !== ''" @click="showDemo(item,index)"></label>
            <label class="el-icon-circle-close upIco" v-if="item.name !== ''" @click="deleteDemo(item,index)"></label>
          </div>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!--  编辑建筑  -->
    <el-dialog title="建筑编辑" :visible.sync="editFromShow" width="900px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel" top="8vh">
      <el-form :model="editfrom" :rules="editFormRules" ref="editFrom">
        <div class="flexCB">
<!--          <el-form-item label="单位名称" class="foItem"  prop="companyname" required>-->
<!--            <el-input v-model="editfrom.companyname"  placeholder="请输入单位名称" disabled></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="建筑名称"  class="foItem" prop="buildname">
            <el-input v-model="editfrom.buildname"  placeholder="请输入建筑名称"></el-input>
          </el-form-item>
          <el-form-item label="建筑类型" class="foItem" prop="buildtype">
            <el-select v-model="editfrom.buildtype" placeholder="请下拉选择建筑类型" class="adSe" style="width: 100%">
              <el-option
                v-for="item in buildTypeList"
                :key="item.dictid"
                :label="item.datavalue"
                :value="item.dictid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="foItem flexAC">
          <el-form-item label="楼层" prop="lfool">
            <el-input v-model="editfrom.lfool" disabled  placeholder="起始楼层" class="flNum"></el-input>
          </el-form-item>
          <div style="line-height: 40px;margin: 0px 4px;"><span>至</span></div>
          <el-form-item prop="rfool" style="margin-bottom: 0px;">
            <el-input v-model="editfrom.rfool"  disabled placeholder="终止楼层" class="flNum"></el-input>
          </el-form-item>
          <el-button class="settingSearch" v-if="cadEditShow === false" @click="foolAdd">添加</el-button>
          <el-button class="settingSearch" v-if="cadEditShow === true" @click="foolEditRea">重置</el-button>
          <el-upload
            accept=".jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
            class="settingExport"
            :disabled="downEditShow"
            :class="downEditShow === true?'disabled':''"
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleSuccessAll">
            <div class="FoolBtn">上传图纸</div>
          </el-upload>
        </div>
        <div class="CAD_box" v-if="cadEditShow">
          <div class="upBox" v-for="(item, index) in editfrom.buildfloor" :key="index">
            <label class="FoolNum">{{ item.label }}层</label>
            <el-upload
              class="cadUpload"
              accept=".jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-change="(file, fileList) => { getIndex(index) }">
              <div class="FoolBtn" v-if="item.name === ''">上传图片</div>
              <label class="upName" v-if="item.name !== ''">{{ item.name }}</label>
            </el-upload>
            <label class="el-icon-view upIcoT" v-if="item.name !== ''" @click="showDemo(item,index)"></label>
            <label class="el-icon-circle-close upIco" v-if="item.name !== ''" @click="deleteDemo(item,index)"></label>
          </div>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!--  建筑详情  -->
    <el-dialog  :visible.sync="detailShow" width="800px" center>
      <div class="flexAC" style="margin-bottom: 12px;">
        <div class="udi_k"></div>
        <p class="udi_t">建筑基本信息</p>
      </div>
<!--      <p class="udi_p">单位名称：{{ detailInfo.companyname }}</p>-->
      <div class="flexCB">
        <p class="udi_s">建筑名称：{{ detailInfo.buildname }}</p>
        <p class="udi_s">建筑类型：{{ detailInfo.datavalue }}</p>
      </div>
      <div class="flexCB">
        <p class="udi_s">是否高层：
          <span v-if="detailInfo.ishigh === true">是</span>
          <span v-if="detailInfo.ishigh === false">否</span>
        </p>
        <p class="udi_s">添加时间：{{ detailInfo.creatime }}</p>
      </div>
      <div class="flexAC" style="margin: 20px 0px 12px;">
        <div class="udi_k"></div>
        <p class="udi_t">楼层信息</p>
      </div>
      <div class="imgShowBox">
        <div class="imgSB" v-for="item in detailInfo.buildfloor" :key="item.index">
          <label class="FoolNum" style="margin-right: 0px;">{{ item.label }}F</label>
          <div class="pathName ">{{ item.name }}</div>
          <label class="el-icon-view upIcoT" style="margin-right: 0px;" v-if="item.name !== ''" @click="showDemo(item,index)"></label>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" class="imgSee" width="900px" center>
      <div class="img_Box">
        <div class="ImgCont">
          <img width="100%" fit="contain" :src="dialogImageUrl" alt="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getbuilding, buildAdd, buildEdit, buildDelete } from '@/http/api/build'
import { nounSearch } from '@/http/api/settingSystem'
import { unitDownQuery } from '@/http/api/unit'
export default {
  name: 'buildList',
  props: ['roleBtn'],
  data () {
    const validateFloor = (rule, value, callback) => {
      var num = Number(value)
      if (value.indexOf('.') === -1) {
        if (num !== 0) {
          callback()
        } else {
          return callback(new Error('输入整数,除去0'))
        }
      } else {
        return callback(new Error('请输入整数'))
      }
    }
    return {
      companyid: '',
      page: 1,
      size: 10,
      total: 0,
      buildname: '',
      buildList: [],
      buildTypeList: [],
      highOption: [
        { name: '是否高层', vaule: '' },
        { name: '是', vaule: 1 },
        { name: '否', vaule: 0 }
      ],
      highOption1: [
        { name: '是否高层', vaule: '' },
        { name: '是', vaule: false },
        { name: '否', vaule: true }
      ],
      high: '',
      addFromShow: false,
      addfrom: {
        companyid: '',
        buildname: '',
        buildtype: '',
        buildfloor: '',
        ishigh: '',
        lfool: '',
        rfool: ''
      },
      formRules: {
        companyid: [{ required: true, message: '请选择单位', trigger: 'blur' }],
        buildname: [
          { required: true, trigger: 'blur', message: '请输入楼/栋名称' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        buildtype: [
          { required: true, trigger: 'blur', message: '请选择建筑类型' }
        ],
        lfool: [
          { required: true, message: '请输入层数', trigger: 'change' },
          { validator: validateFloor, trigger: 'blur' }
        ],
        rfool: [
          { required: true, message: '请输入层数', trigger: 'change' },
          { validator: validateFloor, trigger: 'blur' }
        ]
      },
      downShow: true,
      cadShow: false,
      action: this.BASE_URL + this.$url.PATH.annex,
      imgPathList: [],
      flowIndex: 0,
      editFromShow: false,
      editfrom: {
        lfool: '',
        rfool: ''
      },
      editFormRules: {
        buildname: [
          { required: true, trigger: 'blur', message: '请输入楼/栋名称' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
        ],
        buildtype: [
          { required: true, trigger: 'blur', message: '请选择建筑类型' }
        ],
        lfool: [
          { required: true, message: '请输入层数', trigger: 'change' },
          { validator: validateFloor, trigger: 'blur' }
        ],
        rfool: [
          { required: true, message: '请输入层数', trigger: 'change' },
          { validator: validateFloor, trigger: 'blur' }
        ]
      },
      cadEditShow: false,
      downEditShow: false,
      detailShow: false,
      detailInfo: {},
      companyList: [],
      isSuper: false,
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: this.$url.PATH.Anotherplace
      }
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    // this.companyname = JSON.parse(window.localStorage.getItem('loginInfo')).companyname
    // this.addfrom.companyname = this.companyname
    this.addfrom.companyid = this.companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }
    this.getBuildType()
    this.init()
  },
  methods: {
    getBuildType () {
      // 建筑类型
      const params = { config: 'buildingtype' }
      nounSearch(params).then(res => {
        if (res.status === '1') {
          this.buildTypeList = res.data
        } else {
          this.buildTypeList = []
        }
      })
      // 单位选择下拉框
      unitDownQuery().then(res => {
        if (res.status === '1') {
          this.companyList = res.data
        } else {
          this.companyList = []
        }
      })
    },
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        buildname: this.buildname,
        ishigh: this.high
      }
      getbuilding(params).then(res => {
        if (res.status === '1') {
          this.buildList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.buildList = []
          this.total = 0
        }
      })
    },
    // 搜索
    handleSearch () {
      this.page = 1
      this.init()
    },
    // 重置搜索
    handleReset () {
      this.page = 1
      this.buildname = ''
      this.high = ''
      this.init()
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 展示添加
    handleAdd () {
      this.addFromShow = true
      this.cadShow = false
      this.downShow = true
      this.imgPathList = []
    },
    // 关闭添加
    addFromCancel () {
      this.addFromShow = false
      this.$refs.addFrom.resetFields()
    },
    // 点击添加楼层信息
    foolAdd () {
      var lf = ''
      var rf = ''
      if (this.addFromShow === true) {
        lf = Number(this.addfrom.lfool)
        rf = Number(this.addfrom.rfool)
      } else if (this.editFromShow === true) {
        lf = Number(this.editfrom.lfool)
        rf = Number(this.editfrom.rfool)
      }
      if (lf === 0) {
        this.$message.error('起始楼层不能为0')
        return false
      } else if (rf === 0) {
        this.$message.error('请输入终止楼层')
        return false
      } else if (lf >= rf) {
        this.$message.error('请输入正确的楼层')
        return false
      } else {
        if (this.addFromShow === true) {
          this.cadShow = true
          this.downShow = false
          this.imgPathList = this.getNewFloor(lf, rf)
        } else if (this.editFromShow === true) {
          this.cadEditShow = true
          this.downEditShow = false
          this.editfrom.buildfloor = this.getNewFloor(lf, rf)
        }
      }
    },
    getNewFloor (lf, rf) {
      var arr = []
      if (lf < 0) {
        for (let i = lf; i < 0; i++) {
          const obj = { label: i, name: '', path: '' }
          arr.push(obj)
        }
        for (let j = 1; j <= rf; j++) {
          const obj = { label: j, name: '', path: '' }
          arr.push(obj)
        }
      } else {
        for (let i = lf; i <= rf; i++) {
          const obj = { label: i, name: '', path: '' }
          arr.push(obj)
        }
      }
      return arr
    },
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJPG = 'jpeg/jpg/png/JPEG/JPG/PNG'.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('请上传支持的图片类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      } else {
        this.loading = true
        return isJPG && isLt50M
      }
    },
    getIndex (index) {
      this.flowIndex = index
    },
    handleAvatarSuccess (res, data) {
      if (this.addFromShow === true) {
        if (res.status === '1') {
          this.imgPathList[this.flowIndex].name = data.name
          this.imgPathList[this.flowIndex].path = res.data.filepath
        } else {
          this.$message.error(res.message)
        }
      } else if (this.editFromShow === true) {
        if (res.status === '1') {
          this.editfrom.buildfloor[this.flowIndex].name = data.name
          this.editfrom.buildfloor[this.flowIndex].path = res.data.filepath
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 上传所有
    handleSuccessAll (res, data) {
      // 添加
      if (this.addFromShow === true) {
        if (res.status === '1') {
          for (let i = 0; i < this.imgPathList.length; i++) {
            this.imgPathList[i].name = data.name
            this.imgPathList[i].path = res.data.filepath
          }
        } else {
          this.$message.error(res.message)
        }
      } else if (this.editFromShow === true) { // 编辑
        if (res.status === '1') {
          for (let i = 0; i < this.editfrom.buildfloor.length; i++) {
            this.editfrom.buildfloor[i].name = data.name
            this.editfrom.buildfloor[i].path = res.data.filepath
          }
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 预览图片
    showDemo (item) {
      this.dialogImageUrl = item.path
      this.dialogVisible = true
    },
    deleteDemo (item, index) {
      item.name = ''
      item.path = ''
    },
    foolRes () {
      this.cadShow = false
      this.downShow = true
      this.imgPathList = []
      this.addfrom.lfool = ''
      this.addfrom.rfool = ''
    },
    // 提交添加
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        var buildFloor = this.addfrom.lfool + '~' + this.addfrom.rfool
        const params = {
          // companyid: this.addfrom.companyid,
          buildname: this.addfrom.buildname,
          buildtype: this.addfrom.buildtype,
          buildfloor: this.imgPathList,
          buildlayer: buildFloor
        }
        buildAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 展示详情
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = { ...row }
      console.log(row)
    },
    // 展示编辑框
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
      var buildFloor = this.editfrom.buildlayer.split('~')
      this.editfrom.lfool = buildFloor[0]
      this.editfrom.rfool = buildFloor[1]
      this.cadEditShow = true
      this.downEditShow = false
    },
    // 关闭编辑框
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    foolEditRea () {
      // this.editfrom.lfool = ''
      // this.editfrom.rfool = ''
      this.editfrom.buildfloor = []
      this.cadEditShow = false
      this.downEditShow = true
    },
    // 编辑提交
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        var buildFloor = this.editfrom.lfool + '~' + this.editfrom.rfool
        const params = {
          buildid: this.editfrom.buildid,
          // companyid: this.editfrom.companyid,
          buildname: this.editfrom.buildname,
          buildtype: this.editfrom.buildtype,
          buildfloor: this.editfrom.buildfloor,
          buildlayer: buildFloor
        }
        buildEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        buildDelete({ buildid: row.buildid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.build_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.build_cont_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.build_table {
  margin-top: 20px;
}
.foItem{
  width: 390px;
}
.item_row {
  display: flex;
  justify-content: space-between;
}
.flNum{
  width: 90px;
}
.CAD_box,.imgShowBox{
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.CAD_box::-webkit-scrollbar,.imgShowBox::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.upBox,.imgSB{
  width: 390px;
  height: 35px;
  background: #F6F9FF;
  border-radius: 4px;
  padding: 0px 12px;
  box-sizing: border-box;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}
.imgSB{
  width: 48%;
  margin-right: 4%;
}
.imgSB:nth-child(even){
  margin-right: 0%;
}
.cadUpload{
  width: 90%;
}
.cadUpload>>>.el-upload{
  display: flex;
  align-items: center;
}
.FoolNum{
  display: block;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 21px;
  color: #6F7E90;
  margin-right: 23px;
  width: 38px;
}
.FoolBtn{
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 21px;
  color: #196DF7;
}
.upName{
  width: 83%;
  display: inline-block;
  color: #196DF7;
  text-align: left;
}
.upIcoT{
  color: #52C41A;
  font-size: 16px;
  z-index: 10;
  margin-right: 15px;
}
.upIco{
  color: #FF4D4F;
  font-size: 16px;
  z-index: 10;
}
.disabled{
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
  cursor: not-allowed;
}
.disabled .FoolBtn{
  color: #C0C4CC;
}
.pathName{
  color: #196DF7;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img_Box {
  width: 860px;
  height: 610px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.ImgCont{
  width: auto;
  height: auto;
  position: relative;
}
.ImgCont img {
  width: 100%;
  height: 100%;
}
/*.imgSee>>>.el-dialog__header{*/
/*  padding: 0px;*/
/*}*/
.imgSee>>>.el-dialog__body{
  padding: 0px 20px;
}
</style>
